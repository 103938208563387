/**
 * IColorObject Interface for color object to be used in the Colors component
 *
 * @interface
 */
export interface IColorObject {
  /** Lightest Shade */
  100?: string;
  /** Lighter Shade */
  200?: string;
  /** Light Shade */
  300?: string;
  /** Mid Shade */
  400?: string;
  /** Slight Dark Shade */
  500?: string;
  /** Dark Shade */
  600?: string;
  /** DarkerShade */
  700?: string;
  /** Darkest Shade */
  800?: string;
}

/**
 * Colors List of all available colors in the project
 *
 * @constant
 */
export const Colors: {
  [key: string]: IColorObject;
} = {
  base: {
    100: '#F5EFFB',
    200: '#DFCCF3',
    300: '#8E62BE',
    400: '#5F249F',
    500: '#461B75',
  },
  neutral: {
    100: '#FFFFFF',
    200: '#FAFAFA',
    300: '#E6E6E6',
    400: '#D4D4D4',
    500: '#A3A3A3',
    600: '#818181',
    700: '#595959',
    800: '#2E2E2E',
  },
  error: {
    100: '#E9A995',
    200: '#983B1F',
    300: '#823117',
  },
  success: {
    100: '#92B293',
    200: '#406641',
  },
  warning: {
    100: '#F3AD89',
    200: '#B85623',
  },
  info: {
    100: '#A0C5D2',
    200: '#19617B',
  },
  focus: {
    400: '#5E9ED6',
  },
  facebook: {
    100: '#4267B2',
    200: '#395896',
  },
  nextdoor: {
    100: '#B8EC51',
    200: '#A6D559',
    800: '#006142',
  },
  mkPurple: {
    100: '#F5EFFB',
    400: '#5F249F',
  },
  mkNavy: {
    100: '#F4F8FF',
    300: '#1F4A99',
    400: '#00205B',
  },
  mkOrange: {
    100: '#FCF5E8',
    400: '#F39A0C',
  },
  mkLightBlue: {
    100: '#E8FAFE',
    400: '#6EDCFA',
  },
  mkLightGreen: {
    100: '#E9FDF2',
    400: '#75F5B1',
  },
  mkYellow: {
    100: '#FDFBE8',
    400: '#FFD405',
  },
  mkPink: {
    100: '#FCF0F5',
    400: '#F8ABD1',
  },
  black: {
    100: '#000000',
  },
};

/** Color Type List of Color Types available in the project */
export type ColorType = keyof typeof Colors;

/** Color Shade */
export type ColorShade = keyof IColorObject;
