import AbstractStorage from './abstract-storage';

// Cookie Expiration - 7 days
const COOKIE_EXPIRATION = 7 * 24 * 60 * 60 * 1000;

/**
 * Cookie Storage Cookie Storage is available across all browser tabs. It is not
 * cleared when the tab is closed.
 *
 * @augments AbstractStorage
 * @class
 */
export default class CookieStorage extends AbstractStorage {
  /**
   * Get Get the data from cookies.
   *
   * @param {string} id - The id of the data to get.
   * @returns {object | null} - The data or null if not found.
   */
  get(id: string): object | string | null {
    // If Storage is not available, return null.
    if (typeof window === 'undefined') {
      return null;
    }
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + id + '=');
    if (parts.length === 2) {
      const data = parts.pop()?.split(';').shift();
      const decodedData = decodeURIComponent(data as string);
      let parsedData = null;
      try {
        parsedData = JSON.parse(decodedData);
      } catch (e) {
        parsedData = decodedData;
      }
      return parsedData;
    } else {
      return null;
    }
  }

  /**
   * Set Set the data cookies.
   *
   * @param {string} id - The id of the data to set.
   * @param {object | string} data - The data to set.
   */
  set(id: string, data: object | string): void {
    const date = new Date();
    const value =
      typeof data === 'string'
        ? data
        : encodeURIComponent(JSON.stringify(data));
    date.setTime(date.getTime() + COOKIE_EXPIRATION);
    document.cookie =
      id + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
  }

  /**
   * Delete Delete the Data in Cookies.
   *
   * @param {string} id - The id of the data to delete.
   */
  delete(id: string): void {
    document.cookie = id + '=; Max-Age=-99999999;';
  }
}
